import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@modules/content-api/guard/auth.guard';
import { PreMediaGuard } from '@modules/content-api/guard/pre-media.guard';
import { RedirectGuard } from '@modules/content-api/guard/redirect.guard';
import { ArticleResolver } from '@modules/content-api/resolver/article.resolver';
import { PlayerResolver } from '@modules/content-api/resolver/player.resolver';
import { PressKitResolver } from '@modules/content-api/resolver/press-kit.resolver';

const errorPages: Routes = [
  {
    path: '404',
    loadChildren: () => import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
    data: {
      statusCode: 404,
    },
    canActivate: [RedirectGuard],
  },
  {
    path: '403',
    loadChildren: () => import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
    data: {
      statusCode: 403,
    },
  },
  {
    path: '401',
    loadChildren: () => import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
    data: {
      statusCode: 401,
    },
  },
];

const fixedTopLevelPages: Routes = [
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/magazine-page/magazine-page.module').then((m) => m.MagazinePageModule),
  },
  {
    path: 'search',
    data: {
      breadcrumb: {
        label: 'SEARCH',
        link: 'search',
      },
      title: 'SEARCH',
    },
    loadChildren: () => import('./pages/content-hub/content-hub.module').then((m) => m.ContentHubModule),
  },
  {
    path: 'home', // home page magazine slug
    redirectTo: '',
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome-page/welcome-page.module').then((m) => m.WelcomePageModule),
  },
  {
    path: 'national-media-sites',
    loadChildren: () =>
      import('./pages/national-media-sites/national-media-sites.module').then((m) => m.NationalMediaSitesModule),
  },
  {
    path: 'license-notice',
    loadChildren: () => import('./pages/license-notice/license-notice.module').then((m) => m.ThirdPartyLicensesModule),
  },
  {
    path: 'presscontacts',
    loadChildren: () => import('./pages/contacts-page/contacts-page.module').then((m) => m.ContactsPageModule),
  },
  {
    path: 'newsletter',
    loadChildren: () => import('./pages/newsletter-page/newsletter-page.module').then((m) => m.NewsletterPageModule),
  },
  {
    path: 'pre-media',
    data: {
      title: 'MY_PRE_MEDIA',
    },
    runGuardsAndResolvers: 'always',
    canActivate: [PreMediaGuard],
    loadChildren: () => import('./features/pre-media/pre-media.module').then((m) => m.PreMediaPageModule),
  },
  {
    path: 'legal',
    data: {
      breadcrumb: {
        label: 'PROVIDER_PRIVACY',
        link: '/legal',
      },
    },
    loadChildren: () => import('./features/legal/legal.module').then((m) => m.LegalModule),
  },
];

const topicPages = [
  {
    path: '',
    loadChildren: () => import('./pages/magazine-page/magazine-page.module').then((m) => m.MagazinePageModule),
  },
  {
    path: ':level1',
    loadChildren: () => import('./pages/magazine-page/magazine-page.module').then((m) => m.MagazinePageModule),
  },
  {
    path: ':level1/:level2',
    loadChildren: () => import('./pages/magazine-page/magazine-page.module').then((m) => m.MagazinePageModule),
  },
  {
    path: ':level1/:level2/:level3',
    loadChildren: () => import('./pages/magazine-page/magazine-page.module').then((m) => m.MagazinePageModule),
  },
];

const contentHubs = [
  {
    path: '',
    data: {
      breadcrumb: {
        label: 'ALL_CONTENT',
        link: 'content',
      },
      title: 'ALL_CONTENT',
    },
    loadChildren: () => import('./pages/content-hub/content-hub.module').then((m) => m.ContentHubModule),
  },
  {
    path: ':level1',
    loadChildren: () => import('./pages/content-hub/content-hub.module').then((m) => m.ContentHubModule),
  },
  {
    path: ':level1/:level2',
    loadChildren: () => import('./pages/content-hub/content-hub.module').then((m) => m.ContentHubModule),
  },
  {
    path: ':level1/:level2/:level3',
    loadChildren: () => import('./pages/content-hub/content-hub.module').then((m) => m.ContentHubModule),
  },
];

const eventPages: Routes = [
  {
    path: 'events/:slug',
    data: {
      breadcrumb: {
        label: 'EVENTS',
        link: 'events',
      },
    },
    loadChildren: () => import('./pages/magazine-page/magazine-page.module').then((m) => m.MagazinePageModule),
  },
  {
    path: 'my-events',
    loadChildren: () => import('./pages/my-events-page/my-events-page.module').then((m) => m.MyEventsPageModule),
  },
];

export const MBMediaRoutes: Routes = [
  ...errorPages,
  ...fixedTopLevelPages,
  {
    path: 'article/:slugOrId',
    resolve: {
      article: ArticleResolver,
    },
    loadChildren: () => import('./pages/article-page/article-page.module').then((m) => m.ArticlePageModule),
  },
  {
    path: 'press-kit/:pressKitId',
    resolve: {
      pressKit: PressKitResolver,
    },
    loadChildren: () => import('./pages/press-kit-page/press-kit-page.module').then((m) => m.PressKitPageModule),
  },
  {
    path: 'player/:playerId',
    resolve: {
      stream: PlayerResolver,
    },
    loadChildren: () => import('./pages/player-page/player-page.module').then((m) => m.PlayerPageModule),
  },
  {
    path: 'static/:id',
    loadChildren: () => import('./features/iframe/iframe.module').then((m) => m.IframeModule),
  },
  ...eventPages,
  {
    path: 'content',
    data: {
      keepBreadcrumbs: true,
    },
    children: contentHubs,
  },
  ...topicPages,
  {
    path: '**',
    loadChildren: () => import('./pages/error-page/error-page.module').then((m) => m.ErrorPageModule),
    data: {
      statusCode: 404,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(MBMediaRoutes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'disabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
