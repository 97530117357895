<!-- Fly ins -->
<wb-header-fly-in-container open="{{ (isNavigationLvlOneOpened && !isDesktopView) || isNavigationLvlTwoOpened }}">
  <!-- MOBILE - LVL 1 -->
  <wb-header-mobile-fly-in
    class="mobile-nav-1"
    level="1"
    headline="Mercedes-Benz Media"
    show-star
    star-href="/"
    star-label="{{ 'GO_TO_HOMEPAGE' | translate }}"
    open="{{ isNavigationLvlOneOpened }}"
  >
    <wb-header-button slot="top-bar-end" (click)="closeNavigationMenu()" label="{{ 'CLOSE' | translate }}">
      <wb-icon name="bds/close/24"></wb-icon>
    </wb-header-button>

    <ng-container *ngIf="lvlOneNavigationMenus$ | async as navigationMenus">
      <wb-header-fly-in-item *ngFor="let item of navigationMenus" (click)="openNavigationMenu(2, item)"
        >{{ 'MENU.' + item.id + '.NAME' | translate }}
        <wb-icon *ngIf="item.children?.length" slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon
      ></wb-header-fly-in-item>
    </ng-container>

    <wb-header-tool-list slot="bottom-section">
      <wb-header-tool-list-item href="/search" (click)="openContentHub($event)">
        <wb-icon slot="icon" name="bds/layers/24" flip-rtl></wb-icon>
        {{ 'ALL_CONTENT' | translate }}
      </wb-header-tool-list-item>
    </wb-header-tool-list>

    <div class="bottom-link-container" slot="bottom-section">
      <wb-link href="/presscontacts" variant="small" (click)="openPressContacts($event)">{{
        'PRESS_CONTACTS' | translate
      }}</wb-link>
      <wb-link href="/legal/provider" variant="small" (click)="openImprint($event)">{{
        'PROVIDER_PRIVACY' | translate
      }}</wb-link>
    </div>

    <wb-header-language-menu aria-label="{{ 'LANGUAGE_MENU' | translate }}" slot="footer">
      <wb-header-language-menu-item
        *ngFor="let lang of languages"
        (click)="changeLanguage(lang.isoLang)"
        [active]="currentLanguage === lang.isoLang"
      >
        {{ lang.label }}
      </wb-header-language-menu-item>
    </wb-header-language-menu>
  </wb-header-mobile-fly-in>

  <!-- MOBILE - LVL 2 -->
  <wb-header-mobile-fly-in
    *ngIf="lvlTwoNavigationMenu"
    class="mobile-nav-2"
    level="2"
    headline="{{ 'MENU.' + lvlTwoNavigationMenu.id + '.NAME' | translate }}"
    back-button-label="{{ 'BACK' | translate }}"
    close-button-label="{{ 'CLOSE' | translate }}"
    open="{{ isNavigationLvlTwoOpened }}"
    (wbback)="onBack(1)"
    show-back-button
  >
    <wb-header-button slot="top-bar-end" (click)="closeNavigationMenu()" label="{{ 'CLOSE' | translate }}">
      <wb-icon name="bds/close/24"></wb-icon>
    </wb-header-button>

    <wb-header-fly-in-item *ngIf="lvlTwoNavigationMenu?.children?.length" (click)="navigateToPage(lvlTwoNavigationMenu)"
      >{{ 'ALL_PRESS_CONTENT' | translate }}
    </wb-header-fly-in-item>

    <ng-container *ngFor="let item of lvlTwoNavigationMenu?.children">
      <wb-header-fly-in-item (click)="openNavigationMenu(3, item)">
        {{ 'MENU.' + item.id + '.NAME' | translate }}
        <wb-icon *ngIf="item.children?.length" slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon
      ></wb-header-fly-in-item>
    </ng-container>
  </wb-header-mobile-fly-in>

  <!-- MOBILE - LVL 3 -->
  <wb-header-mobile-fly-in
    *ngIf="lvlThreeNavigationMenu"
    class="mobile-nav-3"
    level="2"
    headline="{{ 'MENU.' + lvlThreeNavigationMenu.id + '.NAME' | translate }}"
    back-button-label="{{ 'BACK' | translate }}"
    close-button-label="{{ 'CLOSE' | translate }}"
    open="{{ isNavigationLvlThreeOpened }}"
    (wbback)="onBack(2)"
    show-back-button
  >
    <wb-header-button slot="top-bar-end" (click)="closeNavigationMenu()" label="{{ 'CLOSE' | translate }}">
      <wb-icon name="bds/close/24"></wb-icon>
    </wb-header-button>

    <wb-header-fly-in-item
      *ngIf="lvlThreeNavigationMenu?.children?.length"
      (click)="navigateToPage(lvlThreeNavigationMenu)"
      >{{ 'ALL_PRESS_CONTENT' | translate }}
    </wb-header-fly-in-item>

    <ng-container *ngFor="let item of lvlThreeNavigationMenu?.children">
      <wb-header-fly-in-item (click)="navigateToPage(item)">
        {{ 'MENU.' + item.id + '.NAME' | translate }}
      </wb-header-fly-in-item>
    </ng-container>
  </wb-header-mobile-fly-in>

  <!-- DESKTOP and TABLET - LVL 1 -->
  <wb-header-desktop-fly-in
    class="tablet-main-nav"
    level="1"
    headline="Mercedes-Benz Media"
    back-button-label="{{ 'BACK' | translate }}"
    close-button-label="{{ 'CLOSE' | translate }}"
    open="{{ isNavigationLvlOneOpened && isTabletView }}"
    (wbclose)="closeNavigationMenu()"
  >
    <ng-container *ngIf="lvlOneNavigationMenus$ | async as navigationMenus">
      <wb-header-fly-in-item
        *ngFor="let item of navigationMenus"
        (click)="openNavigationMenu(2, item)"
        [active]="isActive(item)"
      >
        {{ 'MENU.' + item.id + '.NAME' | translate }}
        <wb-icon *ngIf="item.children?.length" slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon
      ></wb-header-fly-in-item>
    </ng-container>

    <ng-container *ngIf="isTabletView">
      <wb-header-tool-list slot="bottom-section">
        <wb-header-tool-list-item href="/search" (click)="openContentHub($event)">
          <wb-icon slot="icon" name="bds/layers/24" flip-rtl></wb-icon>
          {{ 'ALL_CONTENT' | translate }}
        </wb-header-tool-list-item>
      </wb-header-tool-list>

      <div class="bottom-link-container" slot="bottom-section">
        <wb-link href="/presscontacts" variant="small" (click)="openPressContacts($event)">{{
          'PRESS_CONTACTS' | translate
        }}</wb-link>
        <wb-link href="/legal/provider" variant="small" (click)="openImprint($event)">{{
          'PROVIDER_PRIVACY' | translate
        }}</wb-link>
      </div>
    </ng-container>
  </wb-header-desktop-fly-in>

  <!-- DESKTOP AND TABLET - LVL 2-->
  <wb-header-desktop-fly-in
    *ngIf="lvlTwoNavigationMenu"
    level="{{ isTabletView ? 2 : 1 }}"
    headline="{{ 'MENU.' + lvlTwoNavigationMenu.id + '.NAME' | translate }}"
    back-button-label="{{ 'BACK' | translate }}"
    close-button-label="{{ 'CLOSE' | translate }}"
    open="{{ isNavigationLvlTwoOpened }}"
    (wbback)="onBack(1)"
    (wbclose)="closeNavigationMenu()"
    show-back-button
  >
    <wb-header-fly-in-item *ngIf="lvlTwoNavigationMenu.children?.length" (click)="navigateToPage(lvlTwoNavigationMenu)">
      {{ 'ALL_PRESS_CONTENT' | translate }}
    </wb-header-fly-in-item>

    <wb-header-fly-in-item
      (click)="openNavigationMenu(3, item)"
      *ngFor="let item of lvlTwoNavigationMenu.children"
      [active]="isActive(item)"
    >
      {{ 'MENU.' + item.id + '.NAME' | translate }}
      <wb-icon *ngIf="item.children?.length" slot="icon" name="bds/chevron-right/24" flip-rtl></wb-icon
    ></wb-header-fly-in-item>

    <wb-header-tool-list *ngIf="!isTabletView" slot="bottom-section">
      <wb-header-tool-list-item href="/search" (click)="openContentHub($event)">
        <wb-icon slot="icon" name="bds/layers/24" flip-rtl></wb-icon>
        {{ 'ALL_CONTENT' | translate }}
      </wb-header-tool-list-item>
    </wb-header-tool-list>
  </wb-header-desktop-fly-in>

  <!-- DESKTOP AND TABLET - LVL 3-->
  <wb-header-desktop-fly-in
    *ngIf="lvlThreeNavigationMenu"
    level="2"
    headline="{{ 'MENU.' + lvlThreeNavigationMenu.id + '.NAME' | translate }}"
    back-button-label="{{ 'BACK' | translate }}"
    close-button-label="{{ 'CLOSE' | translate }}"
    open="{{ isNavigationLvlThreeOpened }}"
    (wbback)="onBack(2)"
    (wbclose)="closeNavigationMenu()"
  >
    <wb-header-fly-in-item
      *ngIf="lvlThreeNavigationMenu?.children?.length"
      (click)="navigateToPage(lvlThreeNavigationMenu)"
      >{{ 'ALL_PRESS_CONTENT' | translate }}
    </wb-header-fly-in-item>

    <ng-container *ngFor="let item of lvlThreeNavigationMenu?.children">
      <wb-header-fly-in-item (click)="navigateToPage(item)" [active]="isActive(item)">
        {{ 'MENU.' + item.id + '.NAME' | translate }}
      </wb-header-fly-in-item>
    </ng-container>
  </wb-header-desktop-fly-in>
</wb-header-fly-in-container>
