import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContentAPIService } from '@modules/content-api/content-api.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { NavigationMenuSchema, NavigationMenu } from '../schemas/navigation-menu.schema';

export type NavigationMenuLevel = {
  level: number;
  menu?: NavigationMenu;
};

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  public activeMenuItem: NavigationMenu | undefined;
  public activeMenuPath: NavigationMenu[] = [];

  public activeMenuByLevel: Map<number, NavigationMenu> = new Map();
  public activeNavigationMenuLevel$ = new Subject<NavigationMenuLevel>();

  public menu$: Observable<NavigationMenu[]>;

  public isMenuOpen = false;

  // these can be private, since they are not being accessed outside of this service
  private _menuFlat$: Observable<NavigationMenu[]>;
  private _menuById$: Observable<Map<string, NavigationMenu>>;
  private _menuByPaths$: Observable<Map<string, NavigationMenu>>;

  private _menuLocalisation$: Observable<Map<String, { [k: string]: { NAME: string; PATH: string } }>>;

  // TBD: do we need to store the menu state here?
  private _closeNavigationMenu$ = new ReplaySubject<boolean>();

  constructor(private apiService: ContentAPIService, private translateService: TranslateService) {
    this.menu$ = this.getMenuJSON();

    // Flattened list of menu items
    this._menuFlat$ = this.menu$.pipe(
      map((menuTree) => {
        // menu can have upt to 3 levels, which we want to flatten
        return menuTree.flatMap((menuItem: NavigationMenu) => {
          if (menuItem.children) {
            return [
              menuItem,
              ...menuItem.children.flatMap((menuItem: NavigationMenu) => {
                if (menuItem.children) {
                  return [menuItem, ...menuItem.children];
                } else {
                  return [menuItem];
                }
              }),
            ];
          } else {
            return [menuItem];
          }
        });
      }),
      shareReplay(1)
    );

    // Map to fetch a menu item by id
    this._menuById$ = this._menuFlat$.pipe(
      map((menuList) => {
        return new Map(menuList.map((menuItem) => [menuItem.id, menuItem]));
      }),
      shareReplay(1)
    );

    // Map to fetch a menu item by path
    this._menuByPaths$ = this._menuFlat$.pipe(
      map((menuList: NavigationMenu[]) => {
        return new Map(menuList.flatMap((menuItem) => Object.values(menuItem.path).map((_path) => [_path, menuItem])));
      }),
      shareReplay(1)
    );

    // Map menu names and paths to translated strings
    this._menuLocalisation$ = this._menuFlat$.pipe(
      map((menuList) => {
        const localizedMenus = new Map<String, { [k: string]: { NAME: string; PATH: string } }>();
        this.translateService.langs.forEach((lang: string) => {
          localizedMenus.set(lang, {});
        });

        menuList.forEach((menuItem) => {
          localizedMenus.forEach((_translations, lang) => {
            const langString = lang.toString();
            const localMenu = localizedMenus.get(lang);
            if (localMenu && menuItem.name[langString] && menuItem.path[langString]) {
              localMenu[menuItem.id] = {
                NAME: menuItem.name[langString],
                PATH: menuItem.path[langString],
              };
            }
          });
        });

        return localizedMenus;
      })
    );

    // re-merge menu translations on language change
    combineLatest([this._menuLocalisation$, this.translateService.onLangChange]).subscribe(
      ([localizedMenus, langChangeEvent]) => {
        // add dynamic translations to the current language
        this.translateService.setTranslation(
          langChangeEvent.lang,
          { MENU: localizedMenus.get(langChangeEvent.lang) },
          true
        );
      }
    );
  }

  // TODO implement open / close observable
  public openMenu({ level, menu }: NavigationMenuLevel) {
    this.activeMenuItem = menu;
    this.isMenuOpen = true;
    menu && this.activeMenuByLevel.set(level, menu);
    this.activeNavigationMenuLevel$.next({ level, menu });
  }

  public closeMenu() {
    // TBD: keep or clean up menu state
    this.activeMenuItem = undefined;
    this.isMenuOpen = false;
    this.activeMenuByLevel = new Map();
    this._closeNavigationMenu$.next(true);
    // ...
  }

  /**
   * Fetches the menu / topic tree from the API
   * Uses shareReplay to prevent new API calls on subscription
   *
   * @returns the topic tree
   */
  private getMenuJSON(): Observable<NavigationMenu[]> {
    return this.apiService.getStaticFile(`menu/menu.json`).pipe(
      map((data: any) => {
        const results = NavigationMenuSchema.array().safeParse(data);
        if (results.success) {
          return results.data;
        } else {
          throw results.error;
        }
      }),
      shareReplay(1),
      catchError((error: HttpErrorResponse) => {
        // this error will break the navigation of the app
        console.error('Error fetching menu', error.message);
        return [];
      })
    );
  }

  public navigationMenuPath$(menuItem: NavigationMenu) {
    return this._menuById$.pipe(
      map((menuIdMap) => {
        return this.getNavigationMenuPath(menuIdMap, menuItem);
      })
    );
  }

  getCloseNavigationMenuEvent() {
    return this._closeNavigationMenu$.asObservable();
  }

  /**
   * Check the menu item map find the item that matches with a given path
   *
   * @param path
   * @returns menu item if found, else undefined
   */
  public findNavigationMenuByPath(path: string): Observable<NavigationMenu | undefined> {
    return this._menuByPaths$.pipe(
      map((menuList) => {
        return menuList.get(path);
      })
    );
  }

  /**
   * Check the menu item map find the item that matches with a given id
   *
   * @param id
   * @returns menu item if found, else undefined
   */
  public findNavigationMenuById(id: string): Observable<NavigationMenu | undefined> {
    return this._menuById$.pipe(
      map((menuList) => {
        return menuList.get(id);
      })
    );
  }

  /**
   * Traverse up the menu tree and create a list reflecting the path to the menu item
   *
   * @param menuItem the item we are searching the path to
   * @param menuIdMap the menu tree as a look-up Map by id
   * @returns the menu item path from root to leaf
   */
  private getNavigationMenuPath(menuIdMap: Map<string, NavigationMenu>, menuItem: NavigationMenu): NavigationMenu[] {
    let menuPath = [];
    let currentItem: NavigationMenu | undefined = menuItem;
    while (currentItem) {
      menuPath.push(currentItem);
      currentItem = menuIdMap.get(currentItem.parents[0]);
    }

    return menuPath.reverse();
  }
}
